module.exports = {
  SERVER_URL:
    process.env.REACT_APP_ENV === "live"
      ? process.env.REACT_APP_BACKEND_URL_LIVE
      : process.env.REACT_APP_ENV === "uat"
      ? process.env.REACT_APP_BACKEND_URL_UAT
      : process.env.REACT_APP_BACKEND_URL_LOCAL,

  pageLimit: 10,
  CRYPTO_SECRET_KEY: "Marine2453%^&#",
  roles: {
    admin: "Admin",
    superAdmin: "SuperAdmin",
    user: "User",
  },

  S3Bucket : "https://navigatorsrus-s3.s3.ap-south-1.amazonaws.com/navigatorsrus/",
  Payment_Redirect_URL: "http://staging.sparess.com/stripe-payment-redirect",
  Advertisement_Payment_Redirect_URL:"http://staging.sparess.com/stripe-advertisemet-payment-redirect",

  /* Agora Config */
  APPID: "7ce31730be1e45a1a95c9c7dc58a1adf",
  APPCERTIFICATE: "f0dfb14157b7484bba62dc659ce48e3e",
  EXPTIME: "7200",

  _subCategory: ["New Product", "Used Product", "Lease and Rental", "Service"],

  _HomePages: {
    bannerHeading: "bannerHeading",
    bannerSubText: "bannerSubText",
    bannerVideo: "bannerVideo",
    newCustomerMonthly: "newCustomerMonthly",
    newCustomerMonthlyValue: "newCustomerMonthlyValue",
    increaseInTheSize: "increaseInTheSize",
    increaseInTheSizeValue: "increaseInTheSizeValue",
    yearOfExperience: "yearOfExperience",
    yearOfExperienceValue: "yearOfExperienceValue",
    timeMoreRFQ: "timeMoreRFQ",
    timeMoreRFQValue: "timeMoreRFQValue",
    imageResolutionOneUrl: "imageResolutionOneUrl",
    imageResolutionTwoUrl: "imageResolutionTwoUrl",
    imageResolutionThreeUrl: "imageResolutionThreeUrl",
    seactionHeading: "seactionHeading",
    bannerSubTextSecond: "bannerSubTextSecond",
    satisfiedCustomer: "satisfiedCustomer",
    satisfiedCustomerValue: "satisfiedCustomerValue",
    textOne: "textOne",
    textTwo: "textTwo",
    textThree: "textThree",
  },
  _whoWeArePage: {
    WhoWeAreDescription: "WhoWeAreDescription",
  },
  _aboutUsPage: {
    aboutUsBannerImageUrl: "aboutUsBannerImageUrl",
    ourStoryText: "ourStoryText",
    aboutUsHeading: "aboutUsHeading",
    descriptionOne: "descriptionOne",
    descriptionTwo: "descriptionTwo",
    descriptionThree: "descriptionThree",
    descriptionFour: "descriptionFour",
    descriptionFive: "descriptionFive",
    descriptionSix: "descriptionSix",
    descriptionSeven: "descriptionSeven",
    /* Why We Us Constents */
    whyUsImageUrl: "whyUsImageUrl",
    whyUsDescriptionOne: "whyUsDescriptionOne",
    whyUsDescriptionTwo: "whyUsDescriptionTwo",
    whyUsDescriptionThree: "whyUsDescriptionThree",
    whyUsDescriptionFour: "whyUsDescriptionFour",
    whyUsDescriptionFive: "whyUsDescriptionFive",
    whyUsDescriptionSix: "whyUsDescriptionSix",
    /* Our Mission  */
    ourMissionDescription: "ourMissionDescription",
    ourMissionImageUrl: "ourMissionImageUrl",
    /* Our Investors Constents */
    ourInvestorsDescription: "ourInvestorsDescription",
    ourInvestorsImageOneUrl: "ourInvestorsImageOneUrl",
    ourInvestorsImageTwoUrl: "ourInvestorsImageTwoUrl",
    ourInvestorsImageThreeUrl: "ourInvestorsImageThreeUrl",
    ourInvestorsImageFourUrl: "ourInvestorsImageFourUrl",
  },
  _WhatWeOffer: {
    /* What We Offer Keys */
    bannerImageOneUrl: "bannerImageOneUrl",
    sectionHeadingDisplayProductOrService:
      "sectionHeadingDisplayProductOrService",
    sectionHeadingDisplayProductOrServiceText:
      "sectionHeadingDisplayProductOrServiceText",
    bannerImageTwoUrl: "bannerImageTwoUrl",
    bannerImageThreeUrl: "bannerImageThreeUrl",
    bannerImageFourUrl: "bannerImageFourUrl",
    sectionHeadingPhotosAndPicturesOfTheProduct:
      "sectionHeadingPhotosAndPicturesOfTheProduct",
    sectionHeadingPhotosAndPicturesOfTheProductText:
      "sectionHeadingPhotosAndPicturesOfTheProductText",
    sectionHeadingAdvertisementsBannersOrVideosText:
      "sectionHeadingAdvertisementsBannersOrVideosText",
    sectionHeadingAdvertisementsBannersOrVideos:
      "sectionHeadingAdvertisementsBannersOrVideos",
    sectionHeadingPromotionNotificationsText:
      "sectionHeadingPromotionNotificationsText",
    sectionHeadingPromotionNotifications:
      "sectionHeadingPromotionNotifications",
    sectionHeadingVoiceTextMessagingText:
      "sectionHeadingVoiceTextMessagingText",
    sectionHeadingVoiceTextMessaging: "sectionHeadingVoiceTextMessaging",
    sectionHeadingVideoChatting: "sectionHeadingVideoChatting",
    sectionHeadingVideoChattingText: "sectionHeadingVideoChattingText",
    sectionHeadingPersonalizedDashboard: "sectionHeadingPersonalizedDashboard",
    sectionHeadingPersonalizedDashboardText:
      "sectionHeadingPersonalizedDashboardText",
  },
  _range_product: {
    rangeOfProductImageUrl: "rangeOfProductImageUrl ",
  },
  _terms_condition: {
    termsAndConditionText: "termsAndConditionText",
  },
  _privacy_policy: {
    privacyPolicy: "privacyAndPolicyText",
  },
  _cookie_policy: {
    cookiePolicy: "cookieAndPolicyText",
  },

  _BlogCategory: {
    marine: "Marine",
    air: "Air",
    land: "Land",
  },

  _subscription_type :{
    StandardPlan: "Standard Plan",
    PremiumPlan : "Premium Plan"
  }



};
