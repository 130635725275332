import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Password from "../../../components/Forms/Password";
import { apiRequest, formRequestToRaw } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { _message } from "../../../config/messages";
import { showMessage } from "../../../helpers/toast";
import { setGlobalState } from "../../../redux/dispatch";
import { objectValidate } from "../validator";
import { getAddressFromLatLng, getLatLng } from "../../../helpers/location";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Register() {
  /* Component state */
  const navigate = useNavigate();

  const [location, setLocation] = useState("");
  const [latitude, setLongitude] = useState("");
  const [mobNo, setMobNo] = useState({ value: "", dialCode: "" });


  const handlePhoneChange = (value, { dialCode }) => {
    const limitedValue = value;
    if(limitedValue.length >= 7 &&limitedValue.length <=12 ){
      setMobNo({ ...mobNo, value: limitedValue, dialCode });
    }else{
      showMessage("Phone number must be between 7 and 12 digits.")
    }
  };

  const handlelocation = () => {
    getLatLng((err, { lat, lng }) => {
      if (err) return;
      setLongitude(lat, lng);
      getAddressFromLatLng(lat, lng, (err, resp) => {
        if (err) return;
        setLocation(resp);
      });
    });
  };

  /* Form Ref */
  const registerForm = useRef();
  const termRef = useRef();

  /* Handle api's request */
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(
    //   "mobile ---->",
    //   mobNo.dialCode,
    //   mobNo.value,
    //   mobNo?.value?.replace(mobNo?.dialCode, "")
    // );
    // // mobile ----> 91 918881574438
    let formData = new FormData(registerForm.current);
    formData.append("status", "active");
    formData.append("latitude", latitude);

    let rawReq = formRequestToRaw(formData);
    rawReq.country_code = mobNo?.dialCode;
    rawReq.mobile = mobNo?.value?.replace(mobNo?.dialCode, "");

    /* Validate user fields */
    let validateResponse = objectValidate(rawReq, [
      "companyName",
      "websiteUrl",
    ]);
    if (!validateResponse.isValid) {
      return showMessage(validateResponse.msg);
    } else if (rawReq.password !== rawReq.confirmPassword) {
      return showMessage(_message.passMismatch);
    } else if (!termRef.current.checked) {
      return showMessage(_message.acceptTerms);
    }

    delete rawReq.confirmPassword;
    delete rawReq.terms;
    setGlobalState({ loader: true });
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.register,
      method: "post",
      body: rawReq,
      token: false,
    });
    setGlobalState({ loader: false });
    if (success) {
      showMessage(message);
      navigate(
        "/twostep-verification/" + data.access_token + "/" + rawReq.email
      );
    } else {
      showMessage(message);
    }
  };

  return (
    <>
      <section class="signup-bg">
        <div class="container">
          <div class="row py-4 py-sm-5">
            <div class="col-md-5 col-11 mb-4 mb-sm-0">
              <div class="signup-lefttextbox">

              <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </span>
                
                <h1>Welcome To</h1>
                <img
                  src="/images/signup-logo.png"
                  className="signup-logo"
                  alt="/"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
            <div class="col-md-7">
              <form ref={registerForm} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-11">
                    <div class="signup-righttextbox">
                      <h3>Register</h3>
                      <div class="row mt-sm-4 mt-3">
                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3">
                            <input
                              type="text"
                              name="name"
                              class="form-control input-box"
                              placeholder="Name"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3">
                            <input
                              type="text"
                              name="email"
                              class="form-control input-box"
                              placeholder="Email"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3">
                            <div className="newlocation-group position-relative">
                              <input
                                type="text"
                                name="location"
                                class="form-control input-box"
                                placeholder="Location"
                                value={location?.address}
                                onChange={(e) => {
                                  setLocation({
                                    ...location,
                                    address: e.target.value,
                                  });
                                }}
                              />
                              <img
                                src="/images/Location.png"
                                alt="/"
                                onClick={() => handlelocation()}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3">
                            <div className="">
                              <PhoneInput
                                inputStyle={{
                                  width: "100%",
                                  height: 53,
                                  borderRadius: 8,
                                  backgroundColor: "#F0EFFF",
                                }}
                                country={"us"}
                                name="mobile"
                                onChange={handlePhoneChange}
                                // onChange={(value, { dialCode }) =>
                                //   setMobNo({ ...mobNo, value, dialCode })
                                // }
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3">
                            <div className="mb-sm-4 mb-3">
                              <input
                                type="text"
                                name="country"
                                class="form-control input-box"
                                placeholder="Country"
                                value={location?.country}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3">
                            <input
                              type="text"
                              name="city"
                              class="form-control input-box"
                              placeholder="City"
                              value={location?.city}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3 position-relative">
                            <Password name={"password"} />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div className="mb-sm-4 mb-3 position-relative">
                            <div className="form-group">
                              <Password
                                name={"confirmPassword"}
                                placeholder={"Confirm Password"}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div className="mb-3 mb-sm-0">
                            <input
                              type="text"
                              name="companyName"
                              class="form-control input-box"
                              placeholder="Company Name"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <input
                              type="text"
                              name="websiteUrl"
                              class="form-control input-box"
                              placeholder="Website Url"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-center mt-2 ">
                        <div clas="col-md-12">
                          <div class="form-group new-checkfilled ">
                            <input
                              ref={termRef}
                              name="terms"
                              className="filled-in"
                              type="checkbox"
                              id="Check"
                            />
                            <label class="new-checkfilledlabel" for="Check">
                              <Link to="/terms-conditions">
                                *I agree to NavigatorsRus T&C
                              </Link>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-center">
                        <div class="col-md-7">
                          <button type="submit" class="next-btn btn-block">
                            Next
                          </button>
                        </div>
                      </div>

                      <div class="row justify-content-center">
                        <div class="col-md-6 col-lg-6 col-9">
                          <div class="d-flex justify-content-between align-items-center pt-3">
                            <h5>
                              <a href="/">Already have an Account ?</a>
                            </h5>
                            <button
                              type="button"
                              className=" newlogin-btn"
                              onClick={() => navigate("/Login")}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </div>

                      <h6>or continue with</h6>

                      <div class="social-iconregister">
                        <a href="https://www.facebook.com/">
                          <img src="/images/Facebook.png" alt="/" />
                        </a>{" "}
                        <a href="https://mail.google.com/">
                          <img src="/images/apple.png" alt="" />
                        </a>{" "}
                        <a href="https://www.google.com/">
                          <img src="/images/google.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Register;
