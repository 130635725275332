export function fieldValidate({ field, value }) {
  if (field === "name") {
    if (value === "") {
      return { isValid: false, msg: "Please enter username." };
    } else {
      return { isValid: true };
    }
  } else if (field === "password" || field === "newPassword") {
    if (value === "") {
      return { isValid: false, msg: "Please enter password." };
    } else if (value.trim().length < 8) {
      return { isValid: false, msg: "Password have atleast 8 character." };
    } else {
      return { isValid: true };
    }
  } else if (field === "confirmPassword") {
    if (value === "") {
      return { isValid: false, msg: "Please enter confirm password." };
    } else if (value.trim().length < 8) {
      return {
        isValid: false,
        msg: "Confirm password have atleast 8 character.",
      };
    } else {
      return { isValid: true };
    }
  } else if (field === "otp") {
    if (value === "") {
      return { isValid: false, msg: "Please enter OTP." };
    } else if (value.length !== 6) {
      return { isValid: false, msg: "Please enter 6 digit Otp" };
    }
    const reg = new RegExp("^[0-9]+$");
    const res = reg.test(value);
    if (!res) {
      return { isValid: false, msg: "OTP includes only digits." };
    } else {
      return { isValid: true };
    }
  } else if (field === "mobileNo") {
    if (value === "") {
      return { isValid: false, msg: "Please enter mobileNo." };
    } else if (value.length !== 10) {
      return { isValid: false, msg: "Please enter 10 digit mobile number." };
    }
    const reg = new RegExp("^[0-9]+$");
    const res = reg.test(value);
    if (!res) {
      return { isValid: false, msg: "Mobile number includes digits" };
    } else {
      return { isValid: true };
    }
  } else if (field === "email") {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const test = reg.test(value.toLowerCase());
    if (!test) {
      return { isValid: false, msg: "Please enter a valid email." };
    } else {
      return { isValid: true };
    }
  } else if (field === "age") {
    if (value === "") {
      return { isValid: false, msg: "Age is required" };
    } else if (isNaN(value)) {
      return { isValid: false, msg: "Please enter valid age number" };
    } else if (value < 10 || value > 80) {
      return { isValid: false, msg: "Age should be inbetween 10-80" };
    } else {
      return { isValid: true };
    }
  } else {
    return { isValid: false, msg: "Invalid field" };
  }
}

export function objectValidate(data, options) {
  /* options should be key list that should be ignore */
  for (const [key, value] of Object.entries(data)) {
    if (options && options.includes(key)) {
    } else if (!value) {
      return { isValid: false, msg: `Please enter ${key}` };
    } else if (key === "email") {
      let res = fieldValidate({ field: key, value });
      if (!res.isValid) {
        return res;
      }
    } else if (
      key === "password" ||
      key === "confirmPassword" ||
      key === "newPassword"
    ) {
      let res = fieldValidate({ field: key, value });
      if (!res.isValid) {
        return res;
      }
    } else if (key === "otp") {
      let res = fieldValidate({ field: key, value });
      if (!res.isValid) {
        return res;
      }
    }
  }
  return { isValid: true };
}

export function changePasswordValidate({
  oldPassword,
  newPassword,
  confirmPassword,
}) {
  if (!oldPassword || !newPassword || !confirmPassword) {
    return { isValid: false, msg: `All password field are required` };
  }
  if (newPassword !== confirmPassword) {
    return { isValid: false, msg: `Password mismatch` };
  }
  return { isValid: true };
}

export const validateProductForm = (data, status) => {
  const errors = {};

  if (status !== "draft") {
    if (!data.category_id) {
      errors.category_id = "Category is required.";
    }
    if (!data.sub_category) {
      errors.sub_category = "Sub Category is required.";
    }
    if (!data.title) {
      errors.title = "Product Name is required.";
    }
    if (!data.brand) {
      errors.brand = "Product Make  is required.";
    }
    if (!data.description) {
      errors.description = "Description is required.";
    }

    if (!data.image_url) {
      errors.image_url = "Images is required.";
    }

    if (!data.location) {
      errors.location = "Location is required.";
    }
    if (!data.date) {
      errors.date = " Product Date is required.";
    } else if (isNaN(new Date(data.date).getTime())) {
      errors.date = "Invalid date format.";
    }
    if (!data.unit_price) {
      errors.unit_price = "Unit Price is required.";
    } else if (!Number.isInteger(+data.qty)) {
      errors.unit_price = "Unit Price  must be an integer.";
    }

    if (!data.qty) {
      errors.qty = "Qty is required.";
    }
    if (!data.currency) {
      errors.currency = "Currency is required.";
    }

    if (!data.amount) {
      errors.amount = "Amount is required.";
    }
  }

  return errors;
};

export const validateServiceForm = (data, status) => {
  const errors = {};

  if (status !== "draft") {
    if (!data.category_id) {
      errors.category_id = "Category is required.";
    }
    if (!data.title) {
      errors.title = "Service Product is required.";
    }
    if (!data.sub_category) {
      errors.sub_category = "sub Category is required.";
    }
    if (!data.location) {
      errors.location = "Location  is required.";
    }
    if (!data.type) {
      errors.type = "Service Type is required.";
    }

    if (!data.description) {
      errors.description = "Description is required.";
    }

    if (!data.image_url) {
      errors.image_url = "Images is required.";
    }

    if (!data.from_date) {
      errors.from_date = "Available From Date is required.";
    } else if (isNaN(new Date(data.from_date).getTime())) {
      errors.from_date = "Invalid date format.";
    }

    if (!data.to_date) {
      errors.to_date = "Available To Date is required.";
    } else if (isNaN(new Date(data.to_date).getTime())) {
      errors.to_date = "Invalid date format.";
    }

    if (!data.unit_price) {
      errors.unit_price = "Quantity  is required.";
    } else if (!Number.isInteger(+data.unit_price)) {
      errors.unit_price = "Quantity must be an integer.";
    }

    if (!data.day_rate) {
      errors.day_rate = "Day Rate is  required.";
    } else if (!Number.isInteger(+data.day_rate)) {
      errors.day_rate = "Day Rate  must be an integer.";
    }

    if (!data.currency) {
      errors.currency = "Currency is required.";
    }

    if (!data.amount) {
      errors.amount = "Amount is required.";
    }
  }

  return errors;
};
