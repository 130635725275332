import React, { useEffect, useState } from "react";
import Header from "../include/header";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import Footer from "../include/footer";
import { _aboutUsPage } from "../../config/constants";
import { Helmet } from "react-helmet";

function AboutsUs() {
  const [data, setData] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter((module) => module.module === "about_us");
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const getData = (type) => {
    let homePageData = data?.filter((item) => item?.key === type);
    if (homePageData?.length) {
      return homePageData[0].value;
    } else {
      return "";
    }
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    SeoCall();
    fatchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header></Header>

      <section
        className="about-section"
        style={{
          backgroundImage: `url(${getData(
            _aboutUsPage.aboutUsBannerImageUrl
          )})`,
        }}
      >
        <div className="about-bannertext">
          <h4 className="goldman">About US</h4>
        </div>
      </section>

      <section className="my-sm-5 my-3 our-storysection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-3 mb-sm-0">
              <div className="top-headabout mb-sm-5 mb-3 ">
                <h2 className="goldman">Our Story</h2>
              </div>
              <div>
                {/* <img src={getData()} className="about-leftimg" alt="/" /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-md-11">
                  <div className="about-rightcontent">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(_aboutUsPage.ourStoryText),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-0 pb-sm-0 mb-4 position-relative">
        <div className="container">
          <div className="row mt-sm-4">
            <div className="col-md-12">
              <div className="top-headabout">
                <h2 className="goldman">
                  {getData(_aboutUsPage.aboutUsHeading)}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row mt-sm-4 mt-2">
            <div className="col-md-11 p-sm-0">
              <div className="portal-features">
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <ul>
                      <li>{getData(_aboutUsPage.descriptionOne)}</li>
                      <li>{getData(_aboutUsPage.descriptionTwo)}</li>
                      <li>{getData(_aboutUsPage.descriptionThree)}</li>
                      <li>{getData(_aboutUsPage.descriptionFour)}.</li>
                      <li> {getData(_aboutUsPage.descriptionFive)} </li>
                      <li>{getData(_aboutUsPage.descriptionSix)}</li>
                      <li>{getData(_aboutUsPage.descriptionSeven)}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="images/pseudo.png" className="pseudo-img" alt="/" />
      </section>

      <section className="why-section mt-sm-5 mt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="why-sec mt-3">
                <h2 class="goldman">Why Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-sm-5 pb-4 why-sectioncardbox">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-sm-0 mb-3">
              <div className="why-cardbox">
                <ul>
                  <li>{getData(_aboutUsPage.whyUsDescriptionOne)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionTwo)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionThree)}</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-cardbox">
                <ul>
                  <li>{getData(_aboutUsPage.whyUsDescriptionFour)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionFive)}</li>
                  <li>{getData(_aboutUsPage.whyUsDescriptionSix)}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-missionsection py-sm-5 py-4 mt-sm-4 mt-2">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 mb-sm-0 mb-4">
              <div className="mission-contentbox">
                <h2 className="goldman">OUR MISSION</h2>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <div className="mission-contentbox">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getData(_aboutUsPage.ourMissionDescription),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <img
                  src={getData(_aboutUsPage.ourMissionImageUrl)}
                  className="mission-imgborder"
                  alt="/"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row mt-sm-4 mt-0">
            <div className="col-md-5 mb-sm-0 mb-2">
              <div className="mission-contentbox our-investors">
                <h2 className="goldman">OUR INVESTORS</h2>
              </div>
            </div>
            <div className="col-md-7">
              <div className="our-investors">
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getData(_aboutUsPage.ourInvestorsDescription),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-sm-5 mt-3 mb-sm-4 mb-3">
            <div className="col-md-3 col-6 mb-sm-0 mb-3">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageOneUrl)}
                  alt="/"
                />
              </div>
            </div>
            <div className="col-md-3 col-6 mb-sm-0 mb-3">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageTwoUrl)}
                  alt="/"
                />
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageThreeUrl)}
                  alt="/"
                />
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="about-investors">
                <img
                  src={getData(_aboutUsPage.ourInvestorsImageFourUrl)}
                  alt="/"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default AboutsUs;
