import React, { useEffect, useState } from "react";
import Sparessheader from "../include/sparess-header";
import SparessSidemenu from "../include/sparess-sidemanu";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../frontend/toast";
import moment from "moment";
import "../../assets/seller/css/style.scss";
import { S3Bucket } from "../../config/constants";
// import '../../assets/seller/images';

export default function SellerDashboard() {
  const navigate = useNavigate();

  /* Handle Modal */
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const [currentItem, setCurrentItem] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [advertisement, setAdvertisement] = useState({});
  const [expriyAdvertisement, setExpriyAdvertisement] = useState({});
  // const [productData, setProductData] = useState([]);
  // const [productCategories, setProductCategories] = useState([]);
  // const [productSubCategories, setProductSubCategories] = useState([])
  // const [count, setCount] = useState({})
  // const [servicesProduct, setServicesProdcut] = useState([])

  const fatchAdvertisement = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.advertisementStatusCount,
      method: "get",
    });
    if (success) {
      setAdvertisement(data);
    } else {
      showMessage(message);
    }
  };

  const fetchExpriedAdvertisement = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.advertisementExpiredCount,
      method: "get",
    });
    if (success) {
      setExpriyAdvertisement(data);
    } else {
      showMessage(message);
    }
  };

  // const productFatching = async () => {
  //     setIsOpen(false)
  //     const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.getProducts, method: "get", token: true })
  //     if (success) {
  //         setProductData(data?.products?.rows?.filter(item => item.product_type === 0))
  //         setServicesProdcut(data?.products?.rows?.filter(item => item.product_type == 1))
  //         setCount(data)
  //     } else {
  //         showMessage(message)
  //     }

  // }

  // const productCategoriesList = async () => {
  //     const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.pr, method: 'GET' })
  //     if (success) {
  //         setProductCategories(data?.product_categories.filter(item => item.category_type == "Product"));
  //         setProductSubCategories(data?.product_categories.filter(item => item.category_type == "Service"));
  //     } else {
  //         showMessage(message);
  //     }
  // }

  /* New Start Code by Ekta Gupta */

  const [products, setProducts] = useState([]);
  const [serviceProducts, setServiceProducts] = useState([]);
  const [promotionActiveCount, setPromotionActiveCountt] = useState([]);
  const [promotionExpiryCount, setPromotionExpiryCount] = useState([]);
  const [query, setQuery] = useState({ productType: 0 });
  const [count, setCount] = useState({});

  const getProductListing = async () => {
    setIsOpen(false);
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.getAllProduct,
      method: "get",
      token: true,
    });

    if (success) {
      setProducts(data?.product);
      setServiceProducts(data?.service_product);
    } else {
      showMessage(message);
    }
  };

  const promotionCount = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.promotionCount,
        method: "get",
      });
      if (success) {
        setPromotionActiveCountt(data?.activePromotionCount?.count);
        setPromotionExpiryCount(data?.expiryPromotionCount?.count);
      } else {
        showMessage(message);
      }
    } catch (error) {
      console.log("🚀 ~ promotionCount ~ error:", error);
    }
  };

  const getAllProductCount = async (productType) => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.categoryCount,
      method: "get",
      token: true,
      query: { productType },
    });
    if (success) {
      setCount(data);
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    const productType = query?.productType || 0;
    console.log("🚀 ~ useEffect ~ productType:", productType);
    getAllProductCount(productType);
  }, [query]);

  useEffect(() => {
    getProductListing();
    fetchExpriedAdvertisement();
    fatchAdvertisement();
    promotionCount();
  }, []);

  /* Chat   Code */
  const [profile, setProfile] = useState({});
  const [users, setUsers] = useState([]);
  const [messageCount, setMessageCount] = useState([]);
  const [activeChatInfo, setActiveChatInfo] = useState({
    roomID: -1,
    senderId: "",
    receiverId: "",
    receiverName: "",
    receiverProfileImage: "",
    senderImage: "",
    channelName: "",
  });

  const profileFetch = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      fetchUsers();
      setProfile(data?.user);
    } else {
      showMessage(message);
    }
  };

  const fetchUsers = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.connectUsers,
      method: "GET",
    });
    if (success) {
      setUsers(data?.convertions);
    } else {
      showMessage(message);
    }
  };

  const handelShowChat = (item) => {
    setActiveChatInfo({
      roomID: item?.id,
      channelName: item?.channel_name,
      senderId: profile?.id,
      receiverId:
        profile?.id === item?.buyer_id ? item.seller_id : item?.buyer_id,
      receiverName:
        profile?.id === item?.buyer_id
          ? item?.sellerInfo?.name
          : item.buyerInfo?.name,
      receiverProfileImage:
        profile?.id === item?.buyer_id
          ? item?.sellerInfo?.profile_url
          : item.buyerInfo?.profile_url,
      senderImage: profile?.profile_url,
    });
  };

  const convertionsCount = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.conversationCount,
        method: "GET",
      });
      if (success) {
        setMessageCount(data?.buyerCount);
      } else {
        showMessage(message);
      }
    } catch (error) {
      console.log("🚀 ~ convertionsCount ~ error:", error);
    }
  };

  useEffect(() => {
    profileFetch();
    convertionsCount();
  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        isOpen={isOpen}
        onClose={toggleModal}
        currentItem={currentItem}
        onDelete={getProductListing}
      />
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div className="col-md-9 p-0 col-12">
            <div className="main-box">
              <div className="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>
              <div class="pageinnner-padding">
                <div class="row mt-3">
                  <div class="col-md-4">
                    <div class="table-responsive">
                      <Table className="chat-table">
                        <thead>
                          <tr>
                            <th>
                              <div class="buy-msgimg">
                                <img src="/images/message-icon.png" alt="" />
                              </div>
                            </th>
                            <th>Messages</th>
                            <th>
                              <p>{messageCount}</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((item, index) => {
                            let userInfo = {};
                            if (profile.id !== item?.buyerInfo?.id) {
                              userInfo = item.buyerInfo;
                            } else {
                              userInfo = item.sellerInfo;
                            }
                            return (
                              <ChatCard
                                item={item}
                                profile={profile}
                                userInfo={userInfo}
                                handelShowChat={handelShowChat}
                              />
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <diV class="my-Adsbox">
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                      >
                        <Tab
                          eventKey="home"
                          title={
                            <React.Fragment>
                              <div class="my-ad-icon">
                                <img src="/images/my-ads-icon.png" alt="/" />
                              </div>
                              <h1>My ADs</h1>
                            </React.Fragment>
                          }
                        >
                          <div class="px-0">
                            {/* <div class="d-flex justify-content-center align-items-center pt-4">
                                                            <p>{advertisement?.userAdv?.count + expriyAdvertisement?._expriryCount?.count} </p>
                                                        </div> */}
                            <div class="d-flex justify-content-between align-items-center mt-4 mt-lg-5">
                              <h2>My Active Ads.</h2>
                              <h3>{advertisement?.userAdv?.count}</h3>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-4">
                              <h2>My Expired Ads</h2>
                              <h3>
                                {expriyAdvertisement?._expriryCount?.count}
                              </h3>
                            </div>
                          </div>
                          <div class="mt-4 mt-sm-5 text-center">
                            <Link to="/seller/advertisement-list">
                              <button
                                type="button"
                                class="btn sparessborder-btn mr-2 mr-sm-0 mr-lg-2 mb-0 mb-sm-2 mb-lg-0"
                              >
                                Details
                              </button>
                            </Link>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/Advertisement-rule");
                              }}
                              class="btn sparessfill-btn"
                            >
                              New Ads
                            </button>
                          </div>
                        </Tab>
                        <Tab
                          eventKey="services"
                          title={
                            <React.Fragment>
                              <div class="my-ad-icon">
                                <img src="/images/promo-icon.svg" alt="/" />
                              </div>
                              <h1>My Promotions</h1>
                            </React.Fragment>
                          }
                        >
                          <div class="px-0">
                            <div class="d-flex justify-content-between align-items-center mt-4 mt-lg-5">
                              <h2>My Active Promotions.</h2>
                              <h3>{promotionActiveCount}</h3>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-4">
                              <h2>My Expired Promotions</h2>
                              <h3>{promotionExpiryCount}</h3>
                            </div>
                          </div>

                          <div class="mt-4 mt-sm-5 text-center">
                            <Link to="/seller/promotion">
                              <button
                                type="button"
                                class="btn sparessborder-btn mr-2 mr-sm-0 mr-lg-2 mb-0 mb-sm-2 mb-lg-0"
                              >
                                Details
                              </button>
                            </Link>
                            <button
                              type="button"
                              // onClick={handleShow}
                              class="btn sparessfill-btn"
                              onClick={() => navigate("/seller/promotion")}
                            >
                              New Promotions
                            </button>
                          </div>
                        </Tab>
                      </Tabs>
                    </diV>
                  </div>
                  <div class="col-md-4">
                    <diV class="my-Adsbox">
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                      >
                        <Tab
                          eventKey="home"
                          onClick={() => getAllProductCount(0)}
                          title={
                            <React.Fragment>
                              <div
                                className="my-ad-icon"
                                onClick={() => getAllProductCount(0)}
                              >
                                <img
                                  src="/images/my-ads-icon.png"
                                  alt="/"
                                  onClick={() => getAllProductCount(0)}
                                />
                              </div>
                              <h1>Product</h1>
                            </React.Fragment>
                          }
                        >
                          <div class="px-0">
                            <div className="service-head-text mt-4">
                              <h1 className="mb-2">Sea </h1>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>New</h2>
                                    <h3>{count["Sea New ProductLength"]}</h3>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Used</h2>
                                    <h3>{count["Sea Used ProductLength"]}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="service-head-text mt-4">
                              <h1 className="mb-2">Air </h1>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>New</h2>
                                    <h3>{count["Air New ProductLength"]}</h3>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Used</h2>
                                    <h3>{count["Air Used ProductLength"]}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="service-head-text mt-4">
                              <h1 className="mb-2">Land </h1>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>New</h2>
                                    <h3>{count["Land New ProductLength"]}</h3>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Used</h2>
                                    <h3>{count["Land Used ProductLength"]}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab
                          eventKey="services"
                          onClick={() => getAllProductCount(1)}
                          title={
                            <React.Fragment>
                              <div
                                className="my-ad-icon"
                                onClick={() => getAllProductCount(1)}
                              >
                                <img
                                  src="/images/services-tab-icon.svg"
                                  alt="/"
                                  onClick={() => getAllProductCount(1)}
                                />
                              </div>
                              <h1>Services</h1>
                            </React.Fragment>
                          }
                        >
                          <div class="px-0">
                            <div className="service-head-text mt-4">
                              <h1 className="mb-2">Sea</h1>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Lease & Rental</h2>
                                    <h3>
                                      {count["Sea Lease and RentalLength"]}
                                    </h3>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Services</h2>
                                    <h3>{count["Sea ServiceLength"]}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="service-head-text mt-4">
                              <h1 className="mb-2">Air</h1>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Lease & Rental</h2>
                                    <h3>
                                      {count["Air Lease and RentalLength"]}
                                    </h3>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Services</h2>
                                    <h3>{count["Air ServiceLength"]}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="service-head-text mt-4">
                              <h1 className="mb-2">Land</h1>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Lease & Rental</h2>
                                    <h3>
                                      {count["Land Lease and RentalLength"]}
                                    </h3>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2>Services</h2>
                                    <h3>{count["Land ServiceLength"]}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </diV>
                  </div>

                  <div class="col-md-4 d-none">
                    <div class="dash-tab-1">
                      <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className=""
                      >
                        <Tab
                          eventKey="home"
                          title={
                            <React.Fragment>
                              <div class="d-flex align-items-center">
                                <h1 class="mr-2">Products</h1>
                                {/* <span>{count?.allProductCount}</span> */}
                              </div>
                            </React.Fragment>
                          }
                        ></Tab>
                        <Tab
                          eventKey="services"
                          title={
                            <React.Fragment>
                              <div class="d-flex align-items-center">
                                <h1 class="mr-2">Services</h1>
                                {/* <span>{count?.Servicecount}</span> */}
                              </div>
                            </React.Fragment>
                          }
                        >
                          <div class="table-responsive"></div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="mb-3 user-tabs product-tab"
                        >
                          <Tab eventKey="home" title="Product">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <Table className="posttable posttable-new">
                                    <thead>
                                      <tr>
                                        <th>Product</th>
                                        <th>Posting Date</th>
                                        <th>Exp. Date</th>
                                        <th>Price </th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {products?.slice(0, 5).map((item) => {
                                        return (
                                          <tr>
                                            <td>
                                              <p>
                                                {
                                                  item?.CategoryProduct
                                                    ?.product_name
                                                }{" "}
                                                <br></br>
                                                {item?.brand}
                                              </p>
                                            </td>
                                            <td>
                                              {item?.date ? moment(item?.date).format(
                                                "DD-MM-YYYY"
                                              ) : "No Date"}
                                            </td>
                                            <td>{item?.date ? moment(item?.expiry_date).format(
                                                "DD-MM-YYYY"
                                              ): "No Date"}
                                              </td>
                                            <td>{item?.amount}</td>
                                            <td>
                                              {item?.Category?.category_name}
                                            </td>
                                            <td>{item?.sub_category}</td>
                                            <td>
                                              <div class="row">
                                                <div className="col-md-12">
                                                  {(item?.status ===
                                                    "draft" && (
                                                    <button
                                                      type="button"
                                                      class="btn btn-edit mr-2"
                                                      onClick={() =>
                                                        navigate(
                                                          `/seller/posting/add-product/${item.id}/draft`
                                                        )
                                                      }
                                                    >
                                                      Draft{" "}
                                                    </button>
                                                  )) ||
                                                    (item?.status ===
                                                      "active" && (
                                                      <button
                                                        type="button"
                                                        class="btn btn-edit mr-2"
                                                        onClick={() =>
                                                          navigate(
                                                            `/seller/posting/add-product/${item.id}/draft`
                                                          )
                                                        }
                                                      >
                                                        Update{" "}
                                                      </button>
                                                    )) ||
                                                    (item?.status ===
                                                      "expired" && (
                                                      <button
                                                        type="button"
                                                        class="btn btn-edit mr-2"
                                                      >
                                                        Expired{" "}
                                                      </button>
                                                    ))}
                                                  <button
                                                    type="button"
                                                    class="btn btn-edit"
                                                    onClick={() => {
                                                      toggleModal();
                                                      setCurrentItem(item);
                                                    }}
                                                    // onClick={isOpen}
                                                  >
                                                    <img
                                                      src="/images/delete.svg"
                                                      alt="/"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </Tab>

                          <Tab eventKey="profile" title="Services">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <Table className="posttable posttable-new">
                                    <thead>
                                      <tr>
                                        <th>Product jfbje</th>
                                        <th>Posting Date</th>
                                        <th>Exp. Date</th>
                                        <th>Price </th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {serviceProducts
                                        ?.slice(0, 5)
                                        .map((item) => {
                                          return (
                                            <tr>
                                              <td>
                                                <p>
                                                  {
                                                    item?.CategoryProduct
                                                      ?.product_name
                                                  }{" "}
                                                  <br></br>
                                                  {item?.brand}
                                                </p>
                                              </td>
                                              <td>
                                                {item?.from_date ? moment(item?.from_date).format(
                                                  "DD-MM-YYYY"
                                                ): "No Date"}
                                              </td>
                                              <td>{item?.from_date ? moment(item?.to_date).format(
                                                  "DD-MM-YYYY"
                                                ) : "No Date"}</td>
                                              <td>{item?.amount}</td>
                                              <td>
                                                {item?.Category?.category_name}
                                              </td>
                                              <td>{item?.sub_category}</td>
                                              <td>
                                                <div class="row">
                                                  <div className="col-md-12">
                                                    {(item?.status ===
                                                      "draft" && (
                                                      <button
                                                        type="button"
                                                        class="btn btn-edit mr-2"
                                                        onClick={() =>
                                                          navigate(
                                                            `/seller/addservice/${item.id}/draft`
                                                          )
                                                        }
                                                      >
                                                        Draft{" "}
                                                      </button>
                                                    )) ||
                                                      (item?.status ===
                                                        "active" && (
                                                        <button
                                                          type="button"
                                                          class="btn btn-edit mr-2"
                                                          onClick={() =>
                                                            navigate(
                                                              `/seller/addservice/${item.id}/draft`
                                                            )
                                                          }
                                                        >
                                                          Update{" "}
                                                        </button>
                                                      )) ||
                                                      (item?.status ===
                                                        "expired" && (
                                                        <button
                                                          type="button"
                                                          class="btn btn-edit mr-2"
                                                        >
                                                          Expired{" "}
                                                        </button>
                                                      ))}
                                                    <button
                                                      type="button"
                                                      class="btn btn-edit"
                                                      onClick={() => {
                                                        toggleModal();
                                                        setCurrentItem(item);
                                                      }}
                                                      // onClick={isOpen}
                                                    >
                                                      <img
                                                        src="/images/delete.svg"
                                                        alt="/"
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                    <div class="float-right seemore">
                      <p>
                        <Link to="/seller/posting/products"> See More </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="new-model" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div class="bbtm">
                <p>My Ad Details</p>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="bbtm bbtm-text">
                    <p>Advertisement </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="d-flex status-pop-h">
                    Banner
                    <button type="button" class="btn btn-pop">
                      Update
                    </button>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="bbtm bbtm-text">
                    <p>Duration </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="d-flex status-pop-h">
                    28 Second
                    <button type="button" class="btn btn-pop">
                      Update
                    </button>
                  </h6>
                </div>
              </div>
              onDelete
              <div class="row">
                <div class="col-md-6">
                  <div class="bbtm bbtm-text">
                    <p>Posting Date </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="d-flex status-pop-h">
                    13-01-2023
                    <button type="button" class="btn btn-pop">
                      Expire
                    </button>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="bbtm bbtm-text">
                    <p>Expiry Date </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <h6 class="d-flex status-pop-h">
                    13-03-2023
                    <button type="button" class="btn btn-pop">
                      Update
                    </button>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const DeleteModal = ({ isOpen, onClose, currentItem, onDelete }) => {
  const deleteProduct = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: "v1/seller/service/" + currentItem?.id,
      method: "delete",
    });
    if (success) {
      onDelete();
      showMessage(message);
    } else {
      showMessage(message);
    }
  };

  return (
    <Modal className="new-model dlt-model" show={isOpen} onHide={onClose}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div class="col-md-11 pb-5">
            <div class=" dlt-sec text-center">
              <div className="delete-boxgif">
                <img src="/images/dlt-gif.gif" alt="/" />
              </div>
              <h1 className="mt-3">
                {" "}
                Are you sure!! <br></br>
                you want to delete
              </h1>
              <div class="dlt-sec-btn mt-5">
                <button
                  type="button"
                  class="btn sparessborder-btn mr-2 mr-sm-0 mr-lg-2 mb-0 mb-sm-2 mb-lg-0"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn sparessfill-btn"
                  onClick={deleteProduct}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ChatCard = ({ item, profile, handelShowChat, userInfo }) => {
  const navigate = useNavigate();
  const [unseenCount, setUnseenCount] = useState(0);

  useEffect(() => {
    if (profile.id === item?.buyerInfo?.id) {
      setUnseenCount(item?.seller_unseen_count);
    } else {
      setUnseenCount(item?.buyer_unseen_count);
    }
  }, []);

  const handleRedirect = () => {
    navigate("/seller/connect");
  };

  return (
    <tr onClick={() => handleRedirect()}>
      <td>
        <div class="sparess-user">
          {userInfo?.profile_url ? (
            <img src={API_END_URLS.S3Bucket + userInfo?.profile_url} alt="" />
          ) : (
            <img src="/images/userspic.png" alt="" />
          )}
        </div>
        <span class="green-dots"></span>
      </td>
      <td>
        <h2>{userInfo?.name}</h2>
        <span class="typing-text"></span>
      </td>
      <td>
        {moment(item?.last_msg_at).format("HH:MM")}
        <br />
        <span class="badge badge-red ">
          {Boolean(unseenCount) && unseenCount}
        </span>
      </td>
    </tr>
  );
};
