// import React from 'react';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
// import { Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";

// import Carousel from 'react-bootstrap/Carousel';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import Sonnet from '../../components/Sonnet';
import Slider from "react-slick";
// import Footer from '../include/footer';

var sliderfirstsetting = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
};

function DealsPromotions() {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [shows, setShows] = useState(false);
    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);

    // const [show, setShow] = useState(false);

    const [nav2, setNav1] = useState();

    return (
        <>
            <header className="d-none d-sm-block new-header">
                <div className="tophead">
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-md-3 col-4 pr-0">
                                <button type="button" className="btn  login-btn orange-loginbtn mr-3">Login</button>
                                <button type="button" className="btn  login-btn orange-loginbtn mr-3">Register</button>
                                <img src="images/orange-heart.png" className="heart-headimg" alt="" />
                            </div>
                            <div className="col-md-6 text-center positionnone col-6">
                                <ul className="tophead-right ">
                                    <li><a href="#">Contacts Us</a></li>
                                    <li><a href="#">Blogs</a></li>
                                    <li className="showhim" ><a href="#">Our Services  </a></li>l
                                    <li><a href="#">About Sparess</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-6 text-right col-2">
                                <img src="images/logo.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <header className="d-block d-sm-none new-header">
                <div className="tophead">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-8 pr-0">
                                <a href="#mobmenu" onClick={handleShow} ><img src="images/menu-left-alt.svg" className="mr-1 menu-btn-left" /></a>
                                <a href="#mobmenu" ><img src="images/logo.png" className="logo-mobile" /></a>
                            </div>
                            <div className="col-4 ">
                                <ul className="tophead-right text-right pr-2">
                                    <li><a href="#"><button type="button" className="btn  login-btn login-btn orange-loginbtn ">Login</button></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="services-sectop deals-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="services-tophead">
                                <h1>Deals & Promotions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-4 py-sm-5" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="head-text">
                                        <h1>Advertize Here</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row experiance-slide mt-3 mt-sm-5">
                                <div className="col-md-6">
                                    <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}  {...sliderfirstsetting}>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/new.png" alt="" />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="col-md-6">
                                    <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}  {...sliderfirstsetting}>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="experiance-box new-experiance">
                                                <img src="images/youtube-play.png" alt="" />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-4 pb-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row justify-content-between">
                                <div className="col-md-9 mb-3 mb-sm-0">
                                    <div className="position-relative d-flex">
                                        <input type="email" className="form-control mr-3 deal-input" placeholder="Search for Product Id, Category or something..." />
                                        <img src="images/search-deal.png" className="search-icon" alt="" />
                                        <button type="button" className=" search-btn">Search</button>
                                    </div>
                                </div>

                                <div className="col-md-2 pl-3 pl-sm-0 col-5">
                                    <select className="form-control deals-select">
                                        <option>Rent</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>

                            <div className="tittle-price border-bottom pb-2 d-none d-sm-block">
                                <div className="row mt-5">
                                    <div className="col-md-3 ">
                                        <p>Filters</p>
                                    </div>
                                    <div className="col-md-4 ">
                                        <p>Product</p>
                                    </div>
                                    <div className="col-md-2  text-center">
                                        <p>Price & Rating</p>
                                    </div>
                                    <div className="col-md-1">
                                        <p>Available</p>
                                    </div>
                                    <div className="col-md-2  text-center">
                                        <p>Action</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-3 mb-3 mb-sm-0">
                                    <div className="deals-left">
                                        <div className="padding-boxdeal">
                                            <div className="row mt-4">
                                                <div className="col-md-5 col-6">
                                                    <p><a href="#">Filters</a></p>
                                                </div>
                                                <div className="col-md-7 col-6">
                                                    <h1><a href="#">CLEAR ALL</a></h1>
                                                </div>
                                                <div className="col-md-12 mt-2 mt-sm-3">
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client3" />
                                                        <label for="client3" className="rating-label" >Marine Vessels</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client4" />
                                                        <label for="client4" className="rating-label" >Luxury Vessels</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client5" />
                                                        <label for="client5" className="rating-label" >Product & Parts</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client6" />
                                                        <label for="client6" className="rating-label">Equipment Rental</label>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <hr className="m-2" />

                                        <div className="padding-boxdeal">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="position-relative">
                                                        <input type="email" className="form-control sub-input" placeholder="SubCategory" />
                                                        <img src="images/serach-orange.png" className="sub-inputimg" alt="" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-3">
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client7" />
                                                        <label for="client7" className="rating-label" >Plates and Profiles</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client8" />
                                                        <label for="client8" className="rating-label" >Compressors</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client9" />
                                                        <label for="client9" className="rating-label" >Engines</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client10" />
                                                        <label for="client10" className="rating-label" >Equipment Rental</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client11" />
                                                        <label for="client11" className="rating-label" >Hydraulic Parts</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client12" />
                                                        <label for="client12" className="rating-label" >Fuel</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client13" />
                                                        <label for="client13" className="rating-label" >Life Saving Appliances</label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client14" />
                                                        <label for="client14" className="rating-label" > Combat Equipment </label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client15" />
                                                        <label for="client15" className="rating-label" > Cranes and Davits </label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client16" />
                                                        <label for="client16" className="rating-label"> Portholes </label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client17" />
                                                        <label for="client17" className="rating-label" > Cameras </label>
                                                    </div>
                                                    <div className="deal-radio">
                                                        <input className="with-gap" type="radio" name="register" id="client18" />
                                                        <label for="client18" className="rating-label" > Flooring and Carpets </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 pb-3">
                                                    <h1><a href="#"> SEE ALL </a></h1>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-9">

                                    <div className="boxover-flow">

                                        <div className="price-ratingbox mr-1">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="price-ratingbox mr-1 mt-2">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="price-ratingbox mr-1 mt-2">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="price-ratingbox mr-1 mt-2">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="price-ratingbox mr-1 mt-2">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="price-ratingbox mr-1 mt-2">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="price-ratingbox mr-1 mt-2">
                                            <div className="row align-items-center ">
                                                <div className="col-md-6 mb-2 mb-sm-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-4">
                                                            <div className="price-ratingboximg">
                                                                <img src="images/rating-price.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-7 col-8">
                                                            <h1>Marine Engine  MS Sulzar</h1>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">

                                                        <div className="col-md-4 col-4 ">
                                                            <h6>49.00$</h6>
                                                        </div>
                                                        <div className="col-md-3 col-4 pl-lg-3 pr-lg-3 pl-0 pr-0  ">
                                                            <h6>24 Units</h6>
                                                        </div>
                                                        <div className="col-md-5 text-left text-sm-right  pt-1 pt-sm-0  col-12">
                                                            <button type="button" className=" review-btn deal-btn  mr-2 mr-sm-0">Connect</button>
                                                            <button type="button" className="review-btn price-btn deal-btn "><img src="images/text-msg.png" alt="" /></button>
                                                            <button type="button" className=" review-btn price-btn deal-btn"><img src="images/call.png" alt="" /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section className=" pb-4 pb-sm-5">
                <div className="container">

                    <div className="row justify-content-center pt-0 pt-sm-4 mb-2 mb-sm-4">
                        <div className="col-md-10 ">
                            <div className="row align-items-center ">
                                <div className="col-md-6 ">
                                    <img src="images/mobile.png" alt="" />
                                </div>

                                <div className="col-md-6 mt-3 mt-sm-0">
                                    <div className="row justify-content-center">
                                        <div className="col-md-11 col-lg-10">
                                            <div className="our-mission">
                                                <h1>Try our Sparess App !!</h1>
                                                <p>Get Connected to Buyers,Sellers & Service Providers for your Marine & Offshore Needs, Anywhere and Anytime with your Mobile</p>
                                            </div>

                                            <div className="row mt-2 mt-lg-5 align-items-center">
                                                <div className="col-lg-5 col-md-6 col-6">

                                                    <div className="footbtn service-btn mb-3 mb-lg-4 mb-sm-2" onClick={handleShows} >
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3 pr-0 col-3"><img src="images/black-play.png" alt="" /></div>
                                                            <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Google Play</span></h5></div>
                                                        </div>
                                                    </div>

                                                    <div className="footbtn service-btn" onClick={handleShows} >
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3 pr-0 col-3"><img src="images/black-apple.png" alt="" /></div>
                                                            <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Apple Store</span></h5></div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-lg-7 col-md-6 col-6">
                                                    <div className="qr-imgbox">
                                                        <img src="images/black-scan.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* <Footer /> */}


            <Modal show={show} onHide={handleClose}
                className="filter-popup modal-dialog-slideout home-pop">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {/* <div className="service-head">
                            <img src="images/popup-logo.svg" height="45px" alt="" />
                            <h1>your <span>address</span></h1>
                        </div> */}
                        <img src="images/logo.png" alt="" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="home-popcontent">
                                <ul>
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Blogs</a></li>
                                    <li><a href="#">Our Services</a></li>
                                    <li><a href="#">About Sparess</a></li>
                                    {/* <li><a href="#">Skin care Kits</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" className="btn btn-login">SELECT DATE & TIME</button> */}
                    {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}
                    {/* <Button variant="primary" onClick={handleClose}>Save Changes</Button> */}
                </Modal.Footer>
            </Modal>

            <Modal show={shows} onHide={handleCloses} className="qr-popup">
                <Modal.Header closeButton >
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="qr-popupimg">
                                <img src="images/pop-qr.png" alt="" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" className="btn btn-login">PROCEED TO PAYMENT</button>
                   
                </Modal.Footer> */}
            </Modal>

        </>
    );
}

export default DealsPromotions;