import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import { useParams } from "react-router-dom";
import Header from "../include/header";
import Footer from "../include/footer";
import { Nav } from "react-bootstrap";
import FooterBottam from "../../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";
import { S3Bucket, _BlogCategory } from "../../config/constants";

function Blogs() {
  const params = useParams();
  const [blogCategory, setBlogCategory] = useState([]);
  const [blogDetail, setBlogDetail] = useState({});
  const [relBlog, setRelBlogs] = useState([]);

  const [data, setData] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter((module) => module.module === "blog_image_url");
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const fetchOneBlog = async (blog_type) => {
    const convertedId = params?.id ? parseInt(params.id, 10) : "";
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getOneBlog,
      method: "get",
      query: blog_type ? { blog_type } : { id: convertedId },
    });
    if (success) {
      setBlogDetail(data?.blogDetails ?? {});
      setRelBlogs(data?.relatedBlog ?? []);
    } else {
      showMessage(message);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Show the button when the user scrolls down
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 350,
      behavior: "smooth",
    });
  };

  const handleReadMoreClick = (item) => {
    scrollToTop();
    setBlogDetail(item);
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {
      console.log("🚀 ~ file: blogs.js:72 ~ SeoCall ~ error:", error);
    }
  };

  const category = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCategories,
      method: "get",
    });
    if (success) {
      setBlogCategory(data?.getCategories);
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    fatchData();
    SeoCall();
    fetchOneBlog();
    category();
  }, [params?.id]);

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header></Header>
      <section
        className="about-section blogs-section"
        style={{
          backgroundImage: `url(${data[0]?.value})`,
        }}
      >
        <div className="about-bannertext">
          <h4 className="goldman">Blogs</h4>
        </div>
      </section>

      {blogCategory.length > 0 && blogDetail?.blog_type && (
        <section className="py-sm-5 py-4">
          <div className="container">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={blogDetail?.CMSBlogType?.id}
            >
              <div className="row justify-content-center">
                <div className="col-md-3 col-9">
                  <Nav
                    variant=""
                    className="blogs-tabs"
                    onSelect={(blog_type) => fetchOneBlog(blog_type)}
                  >
                    {blogCategory?.map((item) => {
                      return (
                        <Nav.Item key={item.id}>
                          <Nav.Link eventKey={item?.id.toString()}>
                            {_BlogCategory[item?.blog_type]}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </div>
              </div>

              <div className="row mt-sm-4 mt-3">
                <div className="col-md-12">
                  <Tab.Content>
                    {blogCategory?.map((item) => {
                      return (
                        <Tab.Pane key={item.id} eventKey={item?.id.toString()}>
                          <div className="row mt-sm-3">
                            <div className="col-md-12">
                              <div className="blogs-headtop">
                                <h6>{blogDetail?.blog_title}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-sm-5 mt-3">
                            <div className="col-md-7 mb-3 mb-sm-0">
                              <div className="blog-contentleft">
                                <img
                                  src={S3Bucket + blogDetail?.blog_image}
                                  alt="/"
                                />
                                <h6>{blogDetail?.blog_title}</h6>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: blogDetail?.blog_description,
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-5">
                              {relBlog?.map((item) => {
                                return (
                                  <div className="blog-rightcontent">
                                    <img
                                      src={S3Bucket + item?.blog_image}
                                      alt="/"
                                    />
                                    <h6>{item?.blog_title}</h6>
                                    <button
                                      type="button"
                                      class={`btn view-detailsbtn my-sm-3 my-2 py-sm-2 , scroll-to-top ${
                                        isVisible ? "visible" : ""
                                      }`}
                                      // onClick={() => setBlogDetail(item?.blog_type)}
                                      onClick={() => handleReadMoreClick(item)}
                                    >
                                      {" "}
                                      Read More{" "}
                                      <img
                                        src="/images/arrow-up.svg"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </section>
      )}
      <Footer></Footer>
    </>
  );
}

export default Blogs;
